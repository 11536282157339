import React, { useEffect, useState } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import NewsLetterModal from '../components/NewsLetterModal';
import { useForm } from 'react-hook-form';
import useValidation from '../useHooks/useValidation';
import service from '../services/service';
import Input from '../components/Input';
import { Link } from 'react-router-dom';

interface IBlogPageProps {}

const BlogPage = ({}: IBlogPageProps) => {
  const { handleSubmit, register, errors, reset } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
  });
  const validation = useValidation();
  const [isOpen, setIsOpen] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleClose = () => {
    setIsOpen(false);
    setIsSuccess(false);
  };

  const onSubmit = (data: any) => {
    setIsLoading(true);
    service
      .subscribe(data.email)
      .then((res) => {
        if (res.success) {
          reset();
          setIsSuccess(true);
          setIsOpen(true);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (sessionStorage.getItem('isOpen') !== 'true') {
      sessionStorage.setItem('isOpen', 'true');
      setIsOpen(true);
    }
  }, []);

  return (
    <div className="page-bg">
      <NewsLetterModal
        isOpen={isOpen}
        onClose={handleClose}
        defaultIsSuccess={isSuccess}
      />
      <div className="page page-about">
        <Header />

        <main className="blog-main">
          <div className="container">
            <h1>Blog NewHome</h1>
            <div className="blog-one first">
              <figure className="blog-pic blog-pic3">
                <img
                  src={require('../assets/images/blog/blog10_sm.png')}
                  alt=""
                />
              </figure>
              <article className="blog-content">
                <Link to={'/blog/10'} className="corner" target="_blank">
                  <img src={require('../assets/images/arrow_w.svg')} />
                </Link>
                <div>
                  <p className="blog-date">12.02.2025</p>
                  <h4>
                    <Link to={'/blog/10'}>
                      Newsletter Symbiose : pose du radier
                    </Link>
                  </h4>
                  <div className="blog-content_text">
                    Le projet Symbiose prend forme – Pose du radier en cours !
                  </div>
                </div>
                <Link to={'/blog/10'} className="link-more">
                  Continuer la lecture
                </Link>
              </article>
            </div>
            <div className="blog-one">
              <figure className="blog-pic blog-pic3">
                <img
                  src={require('../assets/images/blog/blog9_sm.jpg')}
                  alt=""
                />
              </figure>
              <article className="blog-content">
                <Link to={'/blog/9'} className="corner" target="_blank">
                  <img src={require('../assets/images/arrow_w.svg')} />
                </Link>
                <div>
                  <p className="blog-date">14.10.2024</p>
                  <h4>
                    <Link to={'/blog/9'}>
                      Newsletter Symbiose : Ouverture de Chantier
                    </Link>
                  </h4>
                  <div className="blog-content_text">
                    Démarrage du projet Symbiose : Le chantier est lancé !
                  </div>
                </div>
                <Link to={'/blog/9'} className="link-more">
                  Continuer la lecture
                </Link>
              </article>
            </div>
            <div className="blog-one">
              <figure className="blog-pic blog-pic3">
                <img
                  src={require('../assets/images/blog/blog8_sm.png')}
                  alt=""
                />
              </figure>
              <article className="blog-content">
                <Link to={'/blog/8'} className="corner" target="_blank">
                  <img src={require('../assets/images/arrow_w.svg')} />
                </Link>
                <div>
                  <p className="blog-date">01.04.2023</p>
                  <h4>
                    <Link to={'/blog/8'}>
                      INTERDICTION DES CHAUFFAGES ÉLECTRIQUES, QUELS IMPACTS
                      POUR LES PROPRIÉTAIRES ?
                    </Link>
                  </h4>
                  <div className="blog-content_text">
                    Fin 2022, le canton de Vaud a annoncé la fin des chauffages
                    électriques d'ici dix ans.
                  </div>
                </div>
                <Link to={'/blog/8'} className="link-more">
                  Continuer la lecture
                </Link>
              </article>
            </div>
            <div className="blog-one">
              <figure className="blog-pic blog-pic3">
                <img
                  src={require('../assets/images/blog/blog7_sm.png')}
                  alt=""
                />
              </figure>
              <article className="blog-content">
                <Link to={'/blog/7'} className="corner" target="_blank">
                  <img src={require('../assets/images/arrow_w.svg')} />
                </Link>
                <div>
                  <p className="blog-date">01.10.2022</p>
                  <h4>
                    <Link to={'/blog/7'}>
                      COMMENT TIRER LE MEILLEUR PARTI DE SON BIEN IMMOBILIER ?
                    </Link>
                  </h4>
                  <div className="blog-content_text">
                    La vente de sa propriété ou de son terrain est toujours une
                    décision difficile.
                  </div>
                </div>
                <Link to={'/blog/7'} className="link-more">
                  Continuer la lecture
                </Link>
              </article>
            </div>
            <div className="blog-one">
              <figure className="blog-pic blog-pic3">
                <img
                  src={require('../assets/images/blog/blog6_sm.png')}
                  alt=""
                />
              </figure>
              <article className="blog-content">
                <Link to={'/blog/6'} className="corner" target="_blank">
                  <img src={require('../assets/images/arrow_w.svg')} />
                </Link>
                <div>
                  <p className="blog-date">01.07.2022</p>
                  <h4>
                    <Link to={'/blog/6'}>
                      VERS UN IMMOBILIER PLUS ÉCOLOGIQUE ET DURABLE
                    </Link>
                  </h4>
                  <div className="blog-content_text">
                    L’immobilier a un impact considérable sur l’environnement.
                  </div>
                </div>
                <Link to={'/blog/6'} className="link-more">
                  Continuer la lecture
                </Link>
              </article>
            </div>
            <div className="blog-one">
              <figure className="blog-pic blog-pic3">
                <img
                  src={require('../assets/images/blog/blog5_sm.png')}
                  alt=""
                />
              </figure>
              <article className="blog-content">
                <Link to={'/blog/5'} className="corner" target="_blank">
                  <img src={require('../assets/images/arrow_w.svg')} />
                </Link>
                <div>
                  <p className="blog-date">13.05.2022</p>
                  <h4>
                    <Link to={'/blog/5'}>
                      L’IMPACT DE L’INFLATION SUR L’ACCÈS À LA PROPRIÉTÉ
                    </Link>
                  </h4>
                  <div className="blog-content_text">
                    L’inflation est un phénomène qui s’est largement accentué
                    ces derniers mois.
                  </div>
                </div>
                <Link to={'/blog/5'} className="link-more">
                  Continuer la lecture
                </Link>
              </article>
            </div>
            <div className="blog-one">
              <figure className="blog-pic blog-pic3">
                <img src={require('../assets/images/blog4_sm.png')} alt="" />
              </figure>
              <article className="blog-content">
                <Link to={'/blog/4'} className="corner" target="_blank">
                  <img src={require('../assets/images/arrow_w.svg')} />
                </Link>
                <div>
                  <p className="blog-date">03.12.2021</p>
                  <h4>
                    <Link to={'/blog/4'}>
                      ÉVALUER LE POTENTIEL DE DÉVELOPPEMENT DE SA PARCELLE
                    </Link>
                  </h4>
                  <div className="blog-content_text">
                    Lorsqu’on est propriétaire d’une parcelle et que l’on
                    souhaite la mettre en vente, il est essentiel d’évaluer sa
                    valeur au plus près de la réalité.
                  </div>
                </div>
                <Link to={'/blog/4'} className="link-more">
                  Continuer la lecture
                </Link>
              </article>
            </div>
            <div className="blog-one">
              <figure className="blog-pic blog-pic3">
                <img
                  src={require('../assets/images/blog/blog3_sm.png')}
                  alt=""
                />
              </figure>
              <article className="blog-content">
                <Link to={'/blog/3'} className="corner">
                  <img src={require('../assets/images/arrow_w.svg')} />
                </Link>
                <div>
                  <p className="blog-date">03.06.2021</p>
                  <h4>
                    <Link to={'/blog/3'}>
                      DÉVELOPPER UNE PROMOTION SUR VOTRE TERRAIN PEUT ÊTRE UN
                      CASSE-TÊTE
                    </Link>
                  </h4>
                  <div className="blog-content_text">
                    L’écologie prend une place de plus en plus importante dans
                    le domaine de l’immobilier. De nombreuses mesures peuvent
                    être mises en oeuvre pour améliorer l’efficience énergétique
                    d’un bâtiment.
                  </div>
                </div>
                <Link to={'/blog/3'} className="link-more">
                  Continuer la lecture
                </Link>
              </article>
            </div>
            <div className="blog-one">
              <figure className="blog-pic blog-pic1">
                <img
                  src={require('../assets/images/blog/blog1_sm.png')}
                  alt=""
                />
              </figure>
              <article className="blog-content">
                <Link to={'/blog/1'} className="corner">
                  <img src={require('../assets/images/arrow_w.svg')} />
                </Link>
                <div>
                  <p className="blog-date">10.03.2021</p>
                  <h4>
                    <Link to={'/blog/1'}>
                      Pertes de chaleur dues au manque D’ISOLATION
                    </Link>
                  </h4>
                  <div className="blog-content_text">
                    L’écologie prend une place de plus en plus importante dans
                    le domaine de l’immobilier. De nombreuses mesures peuvent
                    être mises en oeuvre pour améliorer l’efficience énergétique
                    d’un bâtiment.
                  </div>
                </div>
                <Link to={'/blog/1'} className="link-more">
                  Continuer la lecture
                </Link>
              </article>
            </div>
            <div className="blog-one">
              <figure className="blog-pic blog-pic2">
                <img
                  src={require('../assets/images/blog/blog2_sm.png')}
                  alt=""
                />
              </figure>
              <article className="blog-content">
                <Link to={'/blog/2'} className="corner">
                  <img src={require('../assets/images/arrow_w.svg')} />
                </Link>
                <div>
                  <p className="blog-date">15.02.2021</p>
                  <h4>
                    <Link to={'/blog/2'}>
                      Prix moyen au m<sup>²</sup> canton de vaud
                    </Link>
                  </h4>
                  <div className="blog-content_text">
                    Certains segments de l’immobilier montrent une bonne
                    résilience face à la pandémie de COVID-19. C’est le cas des
                    villas individuelles qui voient une augmentation des
                    demandes de particuliers ...
                  </div>
                </div>
                <Link to={'/blog/2'} className="link-more">
                  Continuer la lecture
                </Link>
              </article>
            </div>
          </div>
        </main>

        <section className="section-subscribe">
          <div className="container">
            <article className="section-subscribe_left">
              <p className="title">NEWSLETTER</p>
              Recevez des conseils et informations sur l'actualité de
              l'immobilier avec notre newsletter.
            </article>
            <form
              onSubmit={handleSubmit(onSubmit)}
              className="section-subscribe_form">
              <Input
                conClassName={'input'}
                errors={errors}
                name={'email'}
                label={'Email*'}
                ref={register(validation.email)}
              />
              <button
                disabled={isLoading}
                type="submit"
                className="btn btn-gradient">
                Je m’inscris
              </button>
            </form>
          </div>
        </section>

        <section className="section-conseil">
            <div className="container">
              <Link to={'/conseil'}><figure className="section-conseil_pic"><img src={require('../assets/images/expertise.svg')} /></figure></Link>
            </div>
        </section>

        <Footer />
      </div>
    </div>
  );
};

export default BlogPage;
