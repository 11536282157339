import React, { useState } from 'react';
import {Link} from 'react-router-dom';
import Header from '../components/Header';
import Footer from '../components/Footer';
import ContactModal from '../components/ContactModal';
import UserModal from '../components/userModal';
import {IUser} from '../components/types/IUser';
import image1 from '../assets/images/about/sp.jpg'
import image2 from '../assets/images/about/lj.jpg'
import image3 from '../assets/images/about/snd.jpg'
import image4 from '../assets/images/about/pv.jpg'
import image5 from '../assets/images/about/sn.jpg'
import image7 from '../assets/images/about/lp.png'
import image8 from '../assets/images/about/rc.jpg'

const AboutPage = () => {
  const [isOpen, setIsOpen] = useState(false);
  const users = [
    {
        id: 1,
        imageUrl: image1,
        name: 'Sébastien Page',
        job: 'Fondateur et administrateur',
        description: 'Sébastien Page est au bénéfice de plusieurs années dans le développement immobilier. Après des études en marketing et plusieurs années passées dans l’horlogerie de luxe, il a mis en place une structure comprenant architectes et direction de travaux avec pour objectif la mise en valeur de bâtiment historique, et progressivement la construction de nouveaux immeubles et groupements de villas.',
        language: 'Français / Anglais',
        linkedin: 'https://www.linkedin.com/in/sebastien-page-7230bb1/'
    },
    {
        id: 2,
        imageUrl: image2,
        name: 'Laurent Jagou',
        job: 'Expert immobilier',
        description: 'Fort d’une expérience de 25 ans dans l’immobilier vaudois et titulaire du Brevet fédéral d’expert immobilier, Laurent Jagou met sa parfaite connaissance du marché romand au profit de NewHome Services en qualité d’expert. Ses connaissances de l’environnement financier, juridique et technique lui permettent d’apporter un avis précis sur l’estimation de la valeur des biens.',
        language: 'Français',
        linkedin: 'https://www.linkedin.com/in/laurent-jagou-410b01267/'
    },
    {
        id: 3,
        imageUrl: image3,
        name: 'Sarah Nadia Dali',
        job: 'Courtière immobilère',
        description: 'Avec une dévotion affirmée pour l’immobilier, Sarah Dali s’est spécialisée dans le courtage après avoir décroché son certificat Immocourtage et exercé pendant plus de 12 ans dans la gérance immobilière. Évoluant dans la dynamique région genevoise, elle tire parti de sa maîtrise de l’italien, de l’anglais et du français pour fournir un service client multilingue et personnalisé, pour donner entière satisfaction à la clientèle.',
        language: 'Français / Anglais / Italien',
        linkedin: 'https://www.linkedin.com/in/sarah-nadia-dali-1193a8a9/'
    },
    {
        id: 4,
        imageUrl: image4,
        name: 'Philippe Vuillemin',
        job: 'Responsable Financier',
        description: 'Philippe Vuillemin, notre responsable financier est diplômé de l’Ecole Hôtelière de Lausanne et titulaire d’une Licence en Sciences Economiques. Philippe a d’abord forgé son expertise en tant que gestionnaire de portefeuilles dans le secteur bancaire avant de continuer son parcours comme Directeur Général d’établissements hôteliers 5 étoiles. Sa maîtrise de la finance et de la gestion, alliée à son engagement envers le service de qualité, joue un rôle crucial dans le pilotage financier de notre société en guidant son développement avec précision et stratégie.',
        language: 'Français',
        linkedin: 'https://www.linkedin.com/in/philippe-vuillemin-b63baa91/'
    },
    {
        id: 5,
        imageUrl: image5,
        name: 'Stéphanie Nuninger',
        job: 'Assistante de direction',
        description: 'Après une première formation dans le domaine de la santé, Stéphanie Nuninger travaille depuis plusieurs années dans le domaine de la construction et de l’immobilier. Elle a occupé plusieurs postes qui lui ont permis d’approfondir ses connaissances dans le domaine de la construction avant de développer de nouvelles connaissances dans l’organisation et la gestion de projets immobiliers.',
        language: 'Français',
        linkedin: ''
    },
    {
        id: 7,
        imageUrl: image7,
        name: 'Lara Page',
        job: 'Assistante Marketing / Communication',
        description: 'Avec une maîtrise en médias et communication, Lara apporte une approche analytique et stratégique à chaque projet. Dotée d’un esprit logique et d’une grande motivation, elle suit chaque dossier avec rigueur et engagement. Sa maîtrise de plusieurs langues lui permet d’interagir aisément avec une clientèle variée, assurant un accompagnement fluide et personnalisé.',
        language: 'Français / Anglais / Italien / Allemand',
        linkedin: 'https://www.linkedin.com/in/lara-page-672777194/'
    },
    {
        id: 8,
        imageUrl: image8,
        name: 'Raphaël Coppola',
        job: 'Courtier Immobilier',
        description: 'Passionné par l’immobilier, Raphaël allie stratégie, écoute et engagement pour offrir des solutions sur mesure à ses clients et garantir la réussite de chaque projet. Avec 25 ans d’expérience commerciale, dont 10 ans dédiés à l’immobilier, il met son expertise au service de chaque transaction. Sa connaissance approfondie du marché local lui permet de garantir un accompagnement sur mesure et une satisfaction totale.',
        language: 'Français',
        linkedin: 'https://www.linkedin.com/in/rapha%C3%ABl-coppola-8aa708100/'
    }
  ]
  const [selectedUser, setSelectedUser] = useState<IUser | null>(null);

  return (
    <div className="page-bg">
      <div className="page page-about">
        <Header />
        <ContactModal isOpen={isOpen} setOpen={setIsOpen} />
        <main className="about-main">
          <div className="container">
            <h1>Équipe</h1>

            <article className="intro-text">
               Notre équipe est composée de professionnels chevronnés ayant une connaissance approfondie du marché immobilier romand. Fort d’une expérience de plusieurs années dans l’immobilier et le développement immobilier en Suisse romande, notre équipe se tient volontiers à votre disposition pour des conseils personnalisés.
            </article>
            <div className="about-list">
                {
                  users.map((user) => (
                      <div key={user.id} className="about-one" onClick={() => setSelectedUser(user)}>
                        <figure className="about-pic">
                          <img
                            src={user.imageUrl}
                            alt={user.name}
                          />
                        </figure>
                        <article className="about-content">

                            <a onClick={(e) => e.stopPropagation()}
                              className="corner"
                              href={user.linkedin}
                              target="_blank">
                               {
                                                           user.linkedin && <img src={require('../assets/images/linkedin.svg')} />
                                                           }
                            </a>
                          <p className="about-name">{user.name}</p>
                          <p className="about-job">{user.job}</p>
                        </article>
                      </div>
                  ))
                }
            </div>
          </div>
        </main>

        <section className="section-contact">
          <div className="container">
            <h2>Contactez-nous</h2>
            <div className="row">
              <div className="col-12 col-lg-4">
                <address className="contact-address">
                  NewHome Services SA
                  <br />
                  Avenue Perdtemps 23
                  <br />
                  1260 Nyon
                </address>
              </div>
              <div className="col-12 col-lg-4">
                <div className="contact-phone">Tél: <a href="tel:+41223629610" onClick={() => {(window as any).dataLayer.push({event: 'phoneClick'})}}>+41 22 362 96 10</a></div>
              </div>
              <div className="col-12 col-lg-4">
                <div className="contact-button">
                  <a
                    className="btn btn-gradient btn-right"
                    onClick={() => {
                        setIsOpen(true);
                        (window as any).dataLayer.push({event: 'contactClick'});
                        }
                    }>
                    <span>Contactez-nous</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>

        <div className="about-map">
          <a
            target={'_blank'}
            href="https://www.google.com/maps/place/NewHome+Services+SA/@46.382542,6.237497,16z/data=!4m5!3m4!1s0x0:0x3a6ac63c13e0961c!8m2!3d46.382542!4d6.237497?hl=fr-FR">
            <img src={require('../assets/images/map.png')} />
          </a>
        </div>

        <section className="section-conseil">
            <div className="container">
              <Link to={'/conseil'}><figure className="section-conseil_pic"><img src={require('../assets/images/expertise.svg')} /></figure></Link>
            </div>
        </section>

        <Footer />

        {selectedUser && <UserModal selectedUser={selectedUser} isOpen={!!selectedUser} setSelectedUser={setSelectedUser}/>}
      </div>
    </div>
  );
};

export default AboutPage;
