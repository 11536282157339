import React, { useState } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { useForm } from 'react-hook-form';
import useValidation from '../useHooks/useValidation';
import service from '../services/service';
import Input from '../components/Input';
import NewsLetterModal from '../components/NewsLetterModal';
import { Link } from 'react-router-dom';

interface IBlogArticle1Props {}

const BlogArticle10 = ({}: IBlogArticle1Props) => {
  const { handleSubmit, register, errors, reset } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
  });
  const validation = useValidation();
  const [isOpen, setIsOpen] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleClose = () => {
    setIsOpen(false);
    setIsSuccess(false);
  };

  const onSubmit = (data: any) => {
    setIsLoading(true);
    service
      .subscribe(data.email)
      .then((res) => {
        if (res.success) {
          reset();
          setIsSuccess(true);
          setIsOpen(true);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <div className="page-bg">
      <NewsLetterModal
        isOpen={isOpen}
        onClose={handleClose}
        defaultIsSuccess={isSuccess}
      />
      <div className="page page-about">
        <Header />
        <main className="blog-main">
          <div className="container blog-big_top">
            <p className="back-link">
              <Link to={'/blog'}>
                <span>Retour au blog</span>
              </Link>
            </p>

            <article className="blog-big">
              <div className="blog-columns2 blog-big_first">
                <div className="blog-columns2_left">
                  <h1>
                    Newsletter Symbiose : pose du radier
                  </h1>
                  <p>&nbsp;</p>
                </div>
                <div className="blog-columns2_right">
                  <figure className="pic">
                    <img
                      src={require('../assets/images/blog/blog10_big.png')}
                    />
                  </figure>
                </div>
              </div>
              <div className="blog-columns2 blog9">
                <div className="blog-columns2_left">
                  <div className="main">
                    Le projet Symbiose prend forme – Pose du radier en cours !
                  </div>
                  <p>
                    Nous sommes heureux de partager avec vous une avancée clé du projet Symbiose au Mont-sur-Lausanne : la pose du radier, une étape déterminante dans la construction de votre futur lieu de vie.
                  </p>
                  <p>
	                  Le radier est une dalle de béton armé qui constitue la base des fondations d’un bâtiment. Il assure la répartition homogène des charges sur le sol et garantit ainsi la stabilité et la solidité de l’ouvrage.
                  </p>
                  <p>
                    Cette étape marque le véritable lancement de la construction. Avant sa mise en place, le terrassement représente une phase d’incertitudes. Il est parfois nécessaire d’adapter les plans en fonction des découvertes effectuées dans le sol, telles que des vestiges archéologiques ou des conditions géotechniques particulières. La pose du radier signifie que ces défis ont été surmontés et que le chantier peut désormais avancer de manière structurée.
                  </p>
                  <p>
                    Nous vous remercions pour votre confiance et nous continuerons à vous tenir informés des prochaines étapes du projet,
                  </p>
                  <p>
	                  L’équipe de NewHome Services
                  </p>
                </div>
              </div>
            </article>
          </div>
        </main>
        <section className="section-subscribe">
          <div className="container">
            <article className="section-subscribe_left">
              <p className="title">NEWSLETTER</p>
              Recevez des conseils et informations sur l'actualité de
              l'immobilier avec notre newsletter.
            </article>
            <form
              onSubmit={handleSubmit(onSubmit)}
              className="section-subscribe_form">
              <Input
                conClassName={'input'}
                errors={errors}
                name={'email'}
                label={'Email*'}
                ref={register(validation.email)}
              />
              <button
                disabled={isLoading}
                type="submit"
                className="btn btn-gradient">
                Je m’inscris
              </button>
            </form>
          </div>
        </section>

        <section className="section-conseil">
            <div className="container">
              <Link to={'/conseil'}><figure className="section-conseil_pic"><img src={require('../assets/images/expertise.svg')} /></figure></Link>
            </div>
        </section>

        <Footer />
      </div>
    </div>
  );
};

export default BlogArticle10;
