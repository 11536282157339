import { RouteConfig } from 'react-router-config';
import MainPage from './pages/MainPage';
import AboutPage from './pages/AboutPage';
import PortfolioPage from './pages/PortfolioPage';
import ServicesPage from './pages/ServicesPage';
import PortfolioItemPage from './pages/PortfolioItemPage';
import BlogPage from './pages/BlogPage';
import BlogArticle1 from './pages/BlogArticle1';
import BlogArticle2 from './pages/BlogArticle2';
import BlogArticle3 from './pages/BlogArticle3';
import BlogArticle4 from './pages/BlogArticle4';
import BlogArticle5 from './pages/BlogArticle5';
import BlogArticle6 from './pages/BlogArticle6';
import BlogArticle7 from './pages/BlogArticle7';
import BlogArticle8 from './pages/BlogArticle8';
import BlogArticle9 from './pages/BlogArticle9';
import BlogArticle10 from './pages/BlogArticle10';
import SalePage from './pages/SalePage';
import ExpertisePage from './pages/ExpertisePage';
import ArboreaPage from './pages/ArboreaPage';
import DeclarationPage from './pages/DeclarationPage';

const routes: RouteConfig[] = [
  {
    path: '/',
    exact: true,
    component: MainPage,
  },
  {
    path: '/about',
    exact: true,
    component: AboutPage,
  },
  {
    path: '/portfolio/:id(\\d+)-:index(\\d+)',
    exact: true,
    component: PortfolioItemPage,
  },
  {
    path: '/portfolio/:id(\\d+)?',
    exact: true,
    component: PortfolioPage,
  },
  {
    path: '/services',
    exact: true,
    component: ServicesPage,
  },
  {
    path: '/blog',
    exact: true,
    component: BlogPage,
  },
  {
    path: '/blog/1',
    exact: true,
    component: BlogArticle1,
  },
  {
    path: '/blog/2',
    exact: true,
    component: BlogArticle2,
  },
  {
    path: '/blog/3',
    exact: true,
    component: BlogArticle3,
  },
  {
    path: '/blog/4',
    exact: true,
    component: BlogArticle4,
  },
  {
    path: '/blog/5',
    exact: true,
    component: BlogArticle5,
  },
  {
    path: '/blog/6',
    exact: true,
    component: BlogArticle6,
  },
  {
    path: '/blog/7',
    exact: true,
    component: BlogArticle7,
  },
  {
    path: '/blog/8',
    exact: true,
    component: BlogArticle8,
  },
  {
    path: '/blog/9',
    exact: true,
    component: BlogArticle9,
  },
  {
    path: '/blog/10',
    exact: true,
    component: BlogArticle10,
  },
  {
    path: '/vendre',
    exact: true,
    component: SalePage,
  },
  {
    path: '/conseil',
    exact: true,
    component: ExpertisePage,
  },
  {
    path: '/arborea',
    exact: true,
    component: ArboreaPage
  },
  {
    path: '/declaration',
    exact: true,
    component: DeclarationPage
  }
];

export default routes;
